<script setup lang="ts">
import { defu } from 'defu'
import type { PopperOptions } from '#ui/types'

const props = defineProps({
  text: {
    type: String,
  },
  popper: {
    type: Object as PropType<PopperOptions>,
  },
  width: {
    type: String,
    default: 'max-w-64', // @see https://tailwindcss.com/docs/max-width
    validator(value: string) {
      if (!/^max-w-(?:\d+|\[.+?])$/.test(value)) {
        console.warn('UiTooltip "width" property must be a Tailwind "max-w-*" class literal')
        return false
      }
      return true
    },
  },
})

const ui = defineUi('popover', {
  /**
   * Arrows are buggy on hover, they seem to be jumping around - I can't find a solution.
   */
  arrow: {
    base: `       z-10 before:w-4 before:h-4 before:relative before:shadow-none`,
    ring: `       before:ring-0`,
    background: ` before:bg-forgd-primary-900`,
    shadow: `     before:shadow-none`,
    placement: `  group-data-[popper-placement*="right"]:-left-2
                  group-data-[popper-placement*="left"]:-right-2
                  group-data-[popper-placement*="top"]:-bottom-1
                  group-data-[popper-placement*="bottom"]:-top-1`,
  },
})

const popper = computed(() => defu(
  props.popper,
  {
    placement: 'top',
    strategy: 'fixed', // ensures top shows on top (otherwise `relative` ancestor elements make it show below)
    arrow: false, // see note above
  },
) as any as PopperOptions)
</script>

<template>
  <UPopover
    data-ui="UiTooltip"
    mode="hover"
    :ui="ui"
    :popper="popper"
    :class="$slots.default ? 'inline-block' : 'inline-flex'"
  >
    <slot v-if="$slots.default" />
    <UiIcon v-else name="i-heroicons-question-mark-circle" color="primary" size="lg" />
    <template #panel>
      <div
        class="p-3 h-auto
               text-xs text-left
               font-display font-normal
               whitespace-normal
               leading-normal
               space-y-2
               bg-forgd-accent-900 text-white
               pointer-events-none
              "
      >
        <div :class="`w-max ${width}`">
          <slot name="text">
            {{ $slots.text || text || 'Sorry, missing tooltip!' }}
          </slot>
        </div>
      </div>
    </template>
  </UPopover>
</template>
